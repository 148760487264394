<template>
  <div class="auth">
    <loader-pay v-if="isLoading" class="loader"></loader-pay>
    <form @submit.prevent="sendAuth" class="auth__form">
      <h1 class="auth__title">Вход</h1>
      <div class="auth__wrong" v-if="isWrongData">
        <p class="auth__alert">Ошибка! Неправильный логин или пароль</p>
      </div>
      <div class="auth__item">
        <form-input
          id="login"
          type="tel"
          label="НОМЕР ТЕЛЕФОНА"
          icon-class="icon-user"
          v-model="login"
        >
        </form-input>
      </div>
      <div class="auth__item">
        <form-input
          id="password"
          type="password"
          label="ПАРОЛЬ"
          icon-class="icon-hide"
          v-model="password"
        >
        </form-input>
      </div>
      <div class="auth__button">
        <form-button
          color="pink-gradient"
          size="md"
          value="ВОЙТИ"
        ></form-button>
      </div>
    </form>
  </div>
</template>
<script>
import FormInput from "../components/forms/FormInput";
import FormButton from "../components/forms/FormButton";
import LoaderPay from "../components/loaders/LoaderPay";
export default {
  name: "Auth",
  components: {LoaderPay, FormButton, FormInput },
  data() {
    return {
      isWrongData: false,
      login: "",
      password: "",
      isLoading: false,
    };
  },
  methods: {
    async sendAuth() {
      this.$store.commit('changeViewType', 'default')
      this.isLoading = true
      this.isWrongData = false;
      let token = await this.$store.dispatch("auth", {
        login: this.login,
        password: this.password,
      });
      if (token.status !== 200) {
        this.isWrongData = true;
        this.isLoading = false
      } else {
        token = token.data;
        localStorage.setItem("accessToken", token.accessToken);
        localStorage.setItem("refreshToken", token.refreshToken);
        await this.$store.dispatch("loadAccount", {
          token: localStorage.getItem("accessToken"),
        });
        this.$router.push("/");
      }
    },
  },
  mounted() {
    this.$store.commit('changeViewType', 'default')
  }
};
</script>
<style lang="scss" scoped>
.auth {
  width: 350px;
  position: relative;
  top: -30px;
  height: auto;
  background: #ffffff;
  box-shadow: 0 2px 7px 1px rgba(88, 88, 88, 0.4);

  &__form {
    padding-top: 34px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 34px;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 100;
  }

  &__alert {
    pointer-events: none;
    height: 40px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
    background: #ffecec;
    border: 1px solid rgba(255, 0, 0, 0.5);
    display: flex;
    align-items: center;
    width: calc(279px - 16px);
    color: #d81919;
    font-size: em(12);
    font-weight: 100;
    user-select: none;
  }

  &__wrong {
    padding-top: 15px;
  }
  &__title {
    font-weight: 400;
    font-size: em(24);
    color: #000000;
    letter-spacing: 0;
  }
  &__item {
    padding-top: 25px;
  }
  &__button {
    padding-top: 30px;
    display: flex;
    align-items: center;
  }
}
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
</style>