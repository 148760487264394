<template>
  <button :class="[size, color]">{{ value }}</button>
</template>

<script>
export default {
  name: "FormButton",
  props: {
    size: String,
    color: String,
    value: String,
  },
};
</script>

<style scoped lang="scss">
button {
  border: 0;
  font-weight: 500;
  font-size: em(17);
  text-align: center;
  letter-spacing: 0;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);

  &.sm {
    width: 180px;
    height: 46px;
  }

  &.xs {
    width: 140px;
    height: 46px;
    font-size: 15px;
  }

  &.xxs {
    width: 110px;
    height: 46px;
    font-size: 13px;
  }

  &.md {
    width: 244px;
    height: 46px;
  }

  &.pink-gradient {
    background-image: linear-gradient(42deg, #e83f94 0%, #f54e5e 100%);
    color: #ffffff;
    border-radius: 8px;
  }

  &.normal {
    font-weight: 400;
  }

  &.circle {
    border-radius: 22px;
  }

  &.white {
    font-family: Roboto, "sans-serif";
    color: #e83f91;
  }

  &.white-circle {
    background-color: white;
    border-radius: 8px;
    color: #e83f91;
  }

  &.pink {
    border: 1px solid #ffffff;
    color: #ffffff;
    text-align: center;
    background-color: #f54e5e;
    border-radius: 8px;
  }

  &:hover {
    filter: brightness(96%);
  }
}
</style>